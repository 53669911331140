/* Base Styles */
body {
  min-height: 95vh;
  max-width: 99vw;
  background-color: gainsboro;
  padding-left: 1rem;
}

.paypal-wrapper {
  width: inherit;
  max-width: 30rem;
}

.contact-row {
  height: 2rem;
  padding-top:3vh;
}

.font-larger {
  font-size: larger;
}

blockquote > p {
  font-size: 0.95rem;
}

.app-title {
  font-variant: petite-caps;
  font-size: 1.2rem;
  color: blue;
  display: contents;
}

.company-text {
  color: black;
  font-size: small;
  font-weight: bold;
  line-height: 2em;
}

.card-list {
  font-size: larger;
  padding-left: 15%;
}

.card-body{
  margin-bottom: 2rem;
}

.nav-link {
  padding-left: 3em !important;
  padding-right: 3em !important;
  font-weight: bolder;
  color: #007bff !important;
}

.ht-100 {
  min-height: 84.5vh;
}

.img-product {
  max-height: 30rem;
  max-width: 45rem;
}

.img-product-sm {
  max-height: 25rem;
  max-width: 25rem;
}

.color-black {
  color: black;
}

.pl-6 {
  padding-left: 6rem;
}

.text-product-title {
  display: contents;
}

.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
}

.bg-burlywood {
  background-color: burlywood;
}

.text-cursive {
  font-family: cursive;
}

.text-lg {
  font-size: 1.25rem;
}

.bg-antiquewhite {
  background-color: antiquewhite;
}

.display-contents {
  display: contents;
}

.dropdown-item {
  color: #007bff !important;
  font-weight: bold;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: burlywood;
}

.dropdown-menu {
  background-color: burlywood;
  padding-top: 0;
  padding-bottom: 0;
}

.btn-link {
  font-weight: bolder;
}

#card-fill {
  min-height: 85vh;
}

#quantity {
  height: 34px;
  width: 48px;
}

#cart-btn {
  display: none;
}

/* Media Queries */
@media (max-width: 575.98px) {
  blockquote > p,
  .card-body,
  p,
  .cart-title {
    font-size: 1rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  h1 {
    font-size: 2rem;
  }

  blockquote > p {
    font-size: 0.7rem;
  }
}

/* Animations */
.order-placed-enter {
  opacity: 0;
  transform: scale(0.9);
}

.order-placed-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.order-placed-exit {
  opacity: 1;
}

.order-placed-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}


.mt-10vh{
  margin-top: 10vh;
}
